.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(src/assets/fonts/InterLight.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(src/assets/fonts/Inter.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(src/assets/fonts/InterMedium.ttf) format("truetype");
  font-weight: 500;
}
/*duplicate for 600 and 700 due to historical inconsistency*/
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(src/assets/fonts/InterSemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(src/assets/fonts/InterSemiBold.ttf) format("truetype");
  font-weight: 700;
}

.react-datepicker {
  font-family: "Inter" !important;
}
.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 1506 !important;
}

.rotate-image {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-pdf__Page__canvas {
  height: 80vh;
  max-height: 600px;
  max-width: 70vw;
}

.annotationLayer {
  display: none;
}

.markdown-editor {
  font-family: "Inter" !important;
}
