* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.metadata_tag {
  align-items: center;
}

.key-value-pair {
  --token-border: rgb(60 116 221 / 50%);
  --token-value-color: #f75151;
  position: relative;
  background-color: #f7515120;
  border: 1px solid #f75151aa;
  border-radius: 3px;
  margin: 0px -1px 0px -1px;
}

.pair-key {
  color: var(--token-value-color);
  padding: 2px 0px 2px 1px;
  border-right: none;
  border-radius: 2px 0px 0px 2px;
}

.pair-value {
  /* background: var(--token-bg); */
  padding: 2px 1px 2px 0px;
  border-left: none;
  border-radius: 0px 2px 2px 0px;
  margin: -1px -2px -1px 0px;
  font-weight: 500;
}

.rc-md-editor.full {
  z-index: 10000 !important;
}

.rc-md-editor .editor-container .sec-md .input {
  height: fit-content;
}

.custom-html-style code {
  padding: 4px !important;
  border-radius: 4px !important;
}

.custom-html-style pre {
  border-radius: 10px !important;
}

.react-datepicker__day--selected {
  background-color: #0491ff;
}

.seho-search-result-json pre {
  font-family: inherit;
}

.seho-search-result-json pre span {
  background-color: rgb(255, 210, 102);
}

#launchnotes-widget {
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 0;
  left: 300px;
  display: none;
}

@media only screen and (max-width: 768px) {
  #launchnotes-widget {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
}

.table__cell {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  /* number of lines to show */
  line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
}

/*https://css-tricks.com/the-current-state-of-styling-scrollbars-in-css/#aa-using-custom-properties-for-styling-scrollbars-in-css*/
.styled-scrollbars {
  --scrollbar-foreground: #b8bfc7;
  --scrollbar-background: "transparent";
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

.styled-scrollbars::-webkit-scrollbar {
  width: 6px; /* Mostly for vertical scrollbars */
  height: 6px; /* Mostly for horizontal scrollbars */
}

.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 8px;
}

.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: var(--scrollbar-background);
}

/*Need for overriding default title styles in snackbar*/
.customSnackbar [data-title] {
  margin-left: 2px;
  font-weight: 400;
}
