.loading {
  font-weight: 700;
  color: #5a7290;
  margin-right: 50px;
}

.loading:after {
  content: ".";
  animation: dots 1.25s steps(5, end) infinite;
}

@keyframes dots {
  0% {
    visibility: hidden;
  }
  20% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #5a7290, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0.25em 0 0 #5a7290, 0.5em 0 0 #5a7290;
  }
}
