.request-form form .hs-richtext > p,
.request-form .submitted-message p,
.request-form form .hs-richtext > div p { 
    font-family: "Inter";
    color: #122945;
}

.request-form form .hs-richtext > div p {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 15px;
}

.request-form form .hs-richtext > p,
.request-form .submitted-message p {
    font-size: 14px !important;
    line-height: 17px;
    font-weight: 500;
}

.request-form form .hs-form-field label span,
.request-form form .hs-form-field .input input {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.5px;
    color: #536378;
}

.request-form form .hs-form-field label span.hs-form-required {
    display: none;
}

.request-form form .hs-form-field {
    margin-top: 24px;
}

.request-form form .hs-form-field .input .hs-input {
    width: calc(100% - 16px);
    height: 36px;
    border: 1px solid #CED3D9;
    border-radius: 6px;
    padding-left: 12px;
    color: #122945;
    outline: none;
}

.request-form form .hs-form-field .input input.invalid {
    border-color: #F75151;
}

.request-form form .hs-form-field .input textarea {
    min-height: 100px;
}

.request-form form .hs-form-field .input select.hs-input {
    width: 100%;
    background-color: #FFF;
}

.request-form form .hs-form-field ul.hs-error-msgs,
.request-form form .hs_error_rollup ul.hs-error-msgs {
    padding: 0;
    list-style-type: none;
    margin-top: 14px;
    margin-bottom: 0;
}

.request-form form .hs-form-field ul.hs-error-msgs li,
.request-form form .hs_error_rollup ul.hs-error-msgs li {
    font-family: "Inter";
    font-size: 12px;
    line-height: 14.5px;
    font-weight: 500;
    color: #F75151;
}

.request-form form .hs-submit .actions input.hs-button {
    display: block;
    width: 140px;
    height: 45px;
    border-radius: 6px;
    margin: 24px auto 0;
    text-transform: capitalize;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    background-color: #E0E8F1;
    color: #122945;
    outline: none;
    border: none;
    cursor: pointer;
}

.request-form form .hs-submit .actions input.hs-button:hover {
    background-color:#122945;
    color: #FFF;
}